/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Sidebar } from "primereact/sidebar";
import Form from "../../../shared/forms/Form";
import FormTabView from "../../../shared/forms/Tabs/FormTabView";
import Field from "../../../shared/forms/Field";
import SolicitudGastos from "./SolicitudGastos";
import useForm from "../../../shared/forms/useForm";
import { Button } from "primereact/button";
import useCajaApi from "../../../api/reportes/useCajaApi";
import { serialize } from 'object-to-formdata';
import moment from "moment";
import ComprobacionGastos from "./ComprobacionGastos";
import { estatus } from "../CajaConstantes";
import alerts from "../../../shared/alerts";
import useSeguridad, { opcionesSeguridad } from "../../../Seguridad/useSeguridad";

const initialValues = {
    estatus: estatus.porComprobar,
    solicitudDeGastos: {
        fecha: moment().startOf('day')._d
    }
};

const SolicitudDetalle = ({ consecutivo, cajaId, onCancel, onSaved, readOnly }) => {
    const visible = consecutivo != null;
    const seguridad = useSeguridad();
    const [soloLectura, setSoloLectura] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const api = useCajaApi();

    initialValues.cajaId = cajaId;

    useEffect(() => {
        setSubmitted(false);
        load(consecutivo).then();
        setSoloLectura(!seguridad.tieneAcceso(opcionesSeguridad.puedeSolicitar) || readOnly);
    }, [consecutivo])

    const {
        values: solicitud,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);

    const load = async (consecutivo) => {
        if (consecutivo === 0 || consecutivo === null) {
            setValues(initialValues);
            return;
        }

        const result = await api.obtener(consecutivo);

        result.solicitudDeGastos.disabled = true;
        result.comprobacionDeGastos.disabled = result.comprobado;
        setValues(result);

    }

    const onSave = async (actualizarAComprobado) => {
        setSubmitted(true);

        if (!isValid) {
            return;
        }

        //si el consecutivo es mayor a cero es que ya estamos guardando comprobantes
        if (consecutivo > 0) {
            await onSaveComprobantes(actualizarAComprobado);
        } else {
            await onSaveSolicitud();
        }

    }

    const onSaveSolicitud = async () => {
        solicitud.solicitudDeGastos.cajaId = cajaId;
        const formData = serialize(solicitud.solicitudDeGastos ?? {}, { indices: true, dotsForObjectNotation: true });
        await api.solicitar(formData);
        onSaved();
    }

    const onSaveComprobantes = async (actualizarAComprobado) => {

        let comprobacionGastos = {
            comprobado: actualizarAComprobado,
            gastos: solicitud.comprobacionDeGastos.gastosPorComprobar
        }

        const formData = serialize(comprobacionGastos, { indices: true, dotsForObjectNotation: true });
        await api.guardarComprobantes(consecutivo, formData);
        onSaved();
    }

    const onDelete = async () => {
        if (await alerts.preguntarSiNoAdvertencia('¿Desea eliminar la solicitud?')) {
            await api.eliminarSolicitud(consecutivo);
            onSaved();
        }
    };

    const onApprove = async () => {
        if (solicitud.solicitudDeGastos?.evidenciaPago?.file == null) {
            alerts.aviso('Debe de adjuntar la evidencia de pago.');
            return;
        }

        solicitud.solicitudDeGastos.cajaId = cajaId;
        const formData = serialize(solicitud.solicitudDeGastos ?? {}, { indices: true, dotsForObjectNotation: true });
        await api.aprobar(formData);
        onSaved();
    };

    return (
        <>
            <Sidebar visible={visible} position="right" dismissable={false}
                className="p-sidebar-bg caja" onHide={onCancel}>
                <Form columns={1} values={solicitud}
                    onChange={onChange}
                    onValidate={onValidate}
                    submitted={submitted}
                    className={`form-container full-height ${submitted ? 'submitted' : ''}`}>
                    <FormTabView>
                        <Field title="Solicitud de Gastos" field="solicitudDeGastos"
                            render={(props) => <SolicitudGastos {...props} cajaId={cajaId} soloLectura={soloLectura} estatusId={solicitud.estatus} />}>
                        </Field>
                        <Field title="Comprobacion de Gastos" field="comprobacionDeGastos" soloLectura={soloLectura}
                            render={(props) => <ComprobacionGastos {...props} />} >
                        </Field>
                    </FormTabView>
                </Form>
                <div className="form-footer">
                    {consecutivo > 0 && seguridad.tieneAcceso(opcionesSeguridad.puedeEliminarSolicitud) && !soloLectura &&
                        <Button label="Eliminar" className="p-button-outlined" type="button" onClick={onDelete} />
                    }
                    {solicitud.estatus === estatus.porComprobar && !soloLectura &&
                        <Button label="Guardar" className="p-button-outlined" type="button"
                            onClick={() => onSave(false)} />

                    }
                    {solicitud.estatus === estatus.solicitada && !soloLectura &&
                        <Button label="Aceptar" className="p-button-outlined" type="button" onClick={onApprove} />
                    }
                </div>
            </Sidebar>
        </>
    )
}

export default SolicitudDetalle;
