/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import agGridHelper from "../../shared/ag-grid-helper";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import useApiConfiguracionCajaChica from "../../api/catalogos/useApiConfiguracionCajaChica";
import { EditIconRenderer, GridEditHeader, ReadOnlyCheckBoxRenderer } from "../../shared/ag-grid-helper/GridRenderers";
import SideBarEditForm from "../../shared/forms/SideBarEditForm";
import Form from "../../shared/forms/Form";
import Field from "../../shared/forms/Field";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormCheckBox from "../../shared/forms/FormFields/FormCheckBox";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import constantes from '../constantes';
import useSeguridad from "../../Seguridad/useSeguridad";

const titleStyle = {
    marginBottom: 0,
    marginTop: '10px'
}


const ConfiguracionCajaChica = () => {
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.configuracionBono);
    const [datos, setDatos] = useState({ cajas: [], tiposGasto: [], conceptos: [] });
    const [currentId, setCurrentId] = useState({});

    //esta variable se utilizara en el parámetro del source de tipo de gastos para forzar a hacer el reload del drop down cada que se guarde algo
    const [contGuardados, setContGuardados] = useState(0);

    const api = useApiConfiguracionCajaChica();

    useEffect(() => {
        load().then();
    }, []);

    const load = async () => {
        const [cajas, tiposGasto, conceptos] = await Promise
            .all([api.filtrarCajas(), api.filtrarTipoGastos(), api.filtrarConceptos()]);
        setDatos({ cajas, tiposGasto, conceptos });
    }

    const gridConfig = {
        rowSelection: 'single',
        enableSorting: true,
        frameworkComponents: {
            readOnlyCheckBoxRenderer: ReadOnlyCheckBoxRenderer,
            editRenderer: EditIconRenderer,
            editHeader: GridEditHeader
        },
        defaultColDef: agGridHelper.defaultColumns
    };

    const sideBarCommon = {
        onSaved: () => {
            load().then();
            setCurrentId({});
            setContGuardados(contGuardados + 1);
        },
        onCancel: () => setCurrentId({})
    };

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            filter: true
        }
    }, []);

    return (
        <>
            <div className="main-screen" style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '15px 30px',
                gridTemplateRows: '40px 1fr 40px 1fr'
            }}>
                <h5 style={titleStyle}>
                    Cajas
                </h5>
                <h5 style={titleStyle}>
                    Tipos de Gastos
                </h5>
                <div className="ag-theme-balham">
                    <AgGridReact
                        {...gridConfig}
                        rowData={datos.cajas}
                        context={{
                            onEdit: (id) => setCurrentId({ caja: id }),
                            onAdd: () => setCurrentId({ caja: 0 })
                        }}
                    >
                        <AgGridColumn field="nombre" headerName="NOMBRE" cellClass={'left'} />
                        <AgGridColumn field="referencia" headerName="NO. DE CUENTA" cellClass={'center'} />
                        <AgGridColumn field="saldo" headerName="SALDO" cellClass={'center'} />
                        <AgGridColumn field="enabled" headerName="Activo" cellClass={'flex center align-center'}
                            cellRenderer={'readOnlyCheckBoxRenderer'} />
                        <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'}
                            cellRenderer="editRenderer" />
                    </AgGridReact>
                </div>
                <div className="ag-theme-balham">
                    <AgGridReact
                        {...gridConfig}
                        rowData={datos.tiposGasto}
                        defaultColDef={defaultColDef}
                        context={{
                            onEdit: (id) => setCurrentId({ tipoGasto: id }),
                            onAdd: () => setCurrentId({ tipoGasto: 0 })
                        }}
                    >
                        <AgGridColumn field="nombre" headerName="NOMBRE" cellClass={'left'} />
                        <AgGridColumn field="caja" headerName="CAJA" cellClass={'left'} />
                        <AgGridColumn field="enabled" headerName="Activo" cellClass={'center'}
                            cellRenderer={'readOnlyCheckBoxRenderer'} />
                        <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'}
                            cellRenderer="editRenderer" />
                    </AgGridReact>
                </div>
                <h5 style={{ ...titleStyle, gridColumnEnd: 'span 2' }}>
                    Conceptos
                </h5>
                <div className="ag-theme-balham">
                    <AgGridReact
                        {...gridConfig}
                        rowData={datos.conceptos}
                        defaultColDef={defaultColDef}
                        context={{
                            onEdit: (id) => setCurrentId({ concepto: id }),
                            onAdd: () => setCurrentId({ concepto: 0 })
                        }}
                    >
                        <AgGridColumn field="nombre" headerName="Nombre" cellClass={'left'} />
                        <AgGridColumn field="tipoGasto" headerName="Tipo de Gasto" cellClass={'left'} />
                        <AgGridColumn field="comprobanteRequerido" headerName="Comprobante Requerido"
                            cellRenderer={'readOnlyCheckBoxRenderer'} cellClass={'center'} />
                        <AgGridColumn field="enabled" headerName="Activo" cellClass={'center'}
                            cellRenderer={'readOnlyCheckBoxRenderer'} />
                        <AgGridColumn maxWidth={60} headerComponent={soloLectura ? '' : 'editHeader'} cellClass={'center'}
                            cellRenderer="editRenderer" />
                    </AgGridReact>
                </div>
            </div>

            <SideBarEditForm id={currentId.caja}
                initialValues={{ enabled: true }}
                getByIdFunction={api.obtenerCaja}
                saveFunction={api.guardarCaja}
                formDisabled={soloLectura}                
                {...sideBarCommon}
            >
                <Form columns={1}>
                    <h5>Caja</h5>
                    <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox} />
                    <Field field={"referencia"} label={"No de Cuenta"} required={true} render={FormTextBox} />
                    <Field field={"enabled"} label={"Activa"} render={FormCheckBox} />
                </Form>
            </SideBarEditForm>

            <SideBarEditForm id={currentId.tipoGasto}
                initialValues={{ enabled: true }}
                getByIdFunction={api.obtenerTipoGasto}
                saveFunction={api.guardarTipoGasto}
                deleteFunction={api.eliminarTipoGasto}
                formDisabled={soloLectura}                
                {...sideBarCommon}
            >
                <Form columns={1}>
                    <h5>Tipo de Gasto</h5>
                    <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox} />
                    <Field field={"cajaIds"} label={"Caja"} source="cajachica/cajas" required={true} render={FormMultiSelect} />
                    <Field field={"enabled"} label={"Activo"} render={FormCheckBox} />
                    <Field field={"administrativo"} label={"Administrativo"} render={FormCheckBox} />
                </Form>
            </SideBarEditForm>

            <SideBarEditForm id={currentId.concepto}
                initialValues={{ enabled: true }}
                getByIdFunction={api.obtenerConcepto}
                saveFunction={api.guardarConcepto}
                deleteFunction={api.eliminarConcepto}
                formDisabled={soloLectura}
                {...sideBarCommon}
            >
                <Form columns={1}>
                    <h5>Concepto</h5>
                    <Field field={"nombre"} label={"Nombre"} required={true} render={FormTextBox} />
                    <Field field={"tipoGastoId"} label={"Tipo de Gasto"} source="cajachica/tipoGastos" params={{ contGuardados }} render={FormDropDown} />
                    <Field field={"comprobanteRequerido"} label={"Comprobante Requerido"} render={FormCheckBox} />
                    <Field field={"enabled"} label={"Activo"} render={FormCheckBox} />
                </Form>
            </SideBarEditForm>
        </>
    )
}

export default ConfiguracionCajaChica;
