import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/inventariosCajas`;
const useApiInventariosCaja = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `InventariosCajas.xlsx`
            }),
    }
};

export default useApiInventariosCaja;