import produce from "immer";
import {Accordion, AccordionTab} from "primereact/accordion";
import React, {useState} from "react";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";

const SftpEDI = ({entity, onChange}) => {
    const [active] = useState(null);

    const actualizarEdi = (value, name, index) => {
        var newValue = produce(entity?.sftpEDI, (draft) => {
            draft[index][name] = value;
        });

        onChange(newValue, 'sftpEDI');
    };

    const agregar = () => {
        var newValue = produce(entity?.sftpEDI, (draft) => {
            draft.push({id: 0});
        });

        onChange(newValue, 'sftpEDI');
    };

    const eliminar = (index) => {
        var newValue = produce(entity?.sftpEDI, (draft) => {
            draft.splice(index, 1);
        });

        onChange(newValue, 'sftpEDI');
    };

    return (<>
        <div className="">
            <table className="table table-borderless">
                <thead className="thead-light">
                <tr>
                    <th scope="col" style={{width: "90%"}}>
                        Sftp Configuración
                    </th>
                    <th className={"input"} style={{width: "10%", textAlign: 'end'}}>
                        <ButtonIcon title={"Agregar Configuración"} iconName={"SiAddthis"}
                                    onClick={agregar}/>
                    </th>
                </tr>
                </thead>
            </table>
        </div>
        {entity?.sftpEDI?.map((item, index) => {
            return (<Accordion style={{marginTop: '1%'}} key={index}
                               activeIndex={active} multiple>
                <AccordionTab header={<>
                    <span>{item?.empresa?.nombre}</span>
                    <div onClick={(event) => event.stopPropagation()}
                         style={{width: '-60px', float: 'right'}}
                         className="d-flex">
                        <ButtonIcon title={"Eliminar"} marginTop={"6px"}
                                    onClick={() => eliminar(index)} iconName={"RiDeleteBinLine"}/>
                    </div>
                </>}>
                    <div className="form-row">
                        <FieldDropDown label={'Empresa'} name={'empresa'} value={item?.empresa} colMd={6}
                                       source={'empresas'}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'URL'} name={'url'} value={item?.url} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Directorio Origen'} name={'carpetaLectura'}
                                      value={item?.carpetaLectura} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Directorio Destino'} name={'carpetaEscritura'}
                                      value={item?.carpetaEscritura} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Usuario'} name={'usuario'} value={item?.usuario} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Password'} name={'password'} value={item?.password} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Formato de Entrada'} name={'formatoEntrada'}
                                      value={item?.formatoEntrada} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}
                                      keyFilter={'int'}/>
                        <FieldTextBox label={'Formato de Salida'} name={'formatoSalida'}
                                      value={item?.formatoSalida} colMd={6}
                                      maxLength={250}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}
                                      keyFilter={'int'}/>
                        <FieldTextBox label={'Identificador'} name={'identificador'} value={item?.identificador}
                                      colMd={6}
                                      maxLength={10}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Zona Horaria'} name={'zonaHoraria'} value={item?.zonaHoraria}
                                      colMd={6}
                                      maxLength={10}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Ambiente'} name={'ambiente'} value={item?.ambiente} colMd={6}
                                      maxLength={1}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Eventos'} showFilter={false} maxSelectedLabels={10}
                                          name={'eventoIds'} colLg={6}
                                          source={"eventos"} colMd={6} value={item?.eventoIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Incidencias'} showFilter={false} maxSelectedLabels={10}
                                          name={'incidenciaIds'} colLg={6}
                                          source={'incidenciasEdi/0'} colMd={6} value={item?.incidenciaIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Identificadores'} showFilter={false} maxSelectedLabels={10}
                                          name={'identificadorIds'} colLg={6}
                                          source={"identificadores"} colMd={6} value={item?.identificadorIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Delimitador'} name={'delimitador'} value={item?.delimitador}
                                      colMd={6} maxLength={2}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Separador'} name={'separador'} value={item?.separador}
                                      colMd={6} maxLength={2}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Seguimiento'} showFilter={false} maxSelectedLabels={10}
                                          name={'seguimientoIds'} colLg={6}
                                          source={"seguimiento"} colMd={6} value={item?.seguimientoIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Rastreo'} showFilter={false} maxSelectedLabels={10}
                                          name={'rastreoIds'} colLg={6}
                                          source={"rastreo"} colMd={6} value={item?.rastreoIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Clave Fecha Llegada'} showFilter={false} maxSelectedLabels={10}
                                          name={'claveFechaLlegadaIds'} colLg={6}
                                          source={"claveFechaLlegada"} colMd={6} value={item?.claveFechaLlegadaIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldMultiSelect label={'Clave Fecha Salida'} showFilter={false} maxSelectedLabels={10}
                                          name={'claveFechaSalidaIds'} colLg={6}
                                          source={"claveFechaSalida"} colMd={6} value={item?.claveFechaSalidaIds}
                                          onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldTextBox label={'Sender'} name={'sender'} value={item?.sender}
                                      colMd={6} maxLength={15}
                                      onChange={(value, name) => actualizarEdi(value, name, index)}/>
                        <FieldCheckBox name="stopSequenceNumber" label="Enviar Stop Sequence Number"
                                       value={item?.stopSequenceNumber}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className="py-1" colMd={6}/>
                        <FieldCheckBox name="enviarRuta" label="Enviar Código de Ruta" value={item?.enviarRuta}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className="py-1" colMd={6}/>
                        <FieldCheckBox name={"enviarLocalizacion"} label={"Enviar Localización"}
                                       value={item?.enviarLocalizacion}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className={"py-1"} colMd={6}/>
                        <FieldCheckBox name={"enviarRespuesta"} label={"Enviar Respuesta"}
                                       value={item?.enviarRespuesta}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className={"py-1"} colMd={6}/>
                        <FieldCheckBox name={"enviarCancelacion"} label={"Enviar Cancelación"}
                                       value={item?.enviarCancelacion}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className={"py-1"} colMd={6}/>
                        <FieldCheckBox name={"formatoCoordenadaEstandar"} label={"Formato Coordenadas Estandar"}
                                       value={item?.formatoCoordenadaEstandar}
                                       onChange={(value, name) => actualizarEdi(value, name, index)}
                                       className={"py-1"} colMd={6}/>
                    </div>
                </AccordionTab>
            </Accordion>)
        })}
    </>);
};

export default SftpEDI;
