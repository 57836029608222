/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import IconLibrary from "../iconLibrary";
import PdfViewer from "../componentes/pdfViewer";
import ImageViewer from "../componentes/imageViewer";
import useInputFile from "./useInputFile";
import { useReadOnly } from "../forms/ReadOnlyProvider";
import {useEmpresa} from "../../layout/EmpresaProvider";

const InputFile = ({ name, value, onChange, error, errors, className, disabled, accept }) => {

    const {
        file,
        uniqueName,
        vistaPrevia,
        setVistaPrevia,
        eliminar,
        download,
        handleUpload,
        updateFile
    } = useInputFile(onChange, name);

    const {empresaId} = useEmpresa();

    const { readOnly } = useReadOnly() ?? {};
    disabled = disabled ?? readOnly;

    useEffect(() => {
        updateFile(value);
    }, [value]);

    return (
        <>
            <div className={`image-upload ${className} ${(error || errors?.[name]) ? 'error' : ''}`}>
                {file.isEmpty && !disabled &&
                    <>

                        <label htmlFor={uniqueName}>
                            <IconLibrary iconName="BsSearch" />
                        </label>
                        <input type="file" id={uniqueName} onChange={handleUpload} accept={accept ? "image/png, image/jpeg" : "" } />
                    </>
                }
                {!file.isEmpty &&
                    <>
                        {(file.extension === 'pdf' || file.extension === 'png' || file.extension === 'jpg') &&
                            <label className="download" onClick={() => setVistaPrevia(true)}>
                                <IconLibrary iconName="GrFormView" />
                            </label>
                        }
                        <label className="download" onClick={download}>
                            <IconLibrary iconName="MdAttachFile" />
                        </label>

                        {!disabled &&
                            <label onClick={eliminar}>
                                <IconLibrary iconName="RiDeleteBinLine" />
                            </label>
                        }
                    </>
                }
            </div>
            {/*Modal con la vista previa del documento pdf*/}
            {(!file.isEmpty && file.extension === 'pdf') &&
                <PdfViewer header="Vista previa del documento" urlApi={`archivos/vistaPrevia/${empresaId}?url=${file.url}`}
                    descargar={download} visible={vistaPrevia}
                    hide={() => setVistaPrevia(false)} />
            }
            {(!file.isEmpty && (file.extension === 'jpg' || file.extension === 'png')) &&
                <ImageViewer header="Vista previa del documento" name="foto" value={file} onChange={null} hide={() => setVistaPrevia(false)} visible={vistaPrevia} urlApi={`archivos/vistaPrevia/${empresaId}?url=${file.url}`} />
            }
        </>
    )
};


export default InputFile;
